.animated-line {
    position: relative;
    margin: 20px 0;
}

.line {
    width: 100%;
    height: 2px;
    background-color: #000000;
    position: absolute;
    animation: stretch 1s ease-in-out infinite alternate;
}

@keyframes stretch {
    0% {
        width: 100%;
    }
    100% {
        width: 0;
    }
}