.custom-header {
  font-size: 40px;
  font-weight: 500;
  background-image: linear-gradient(to left, #1f095e, #2885f0);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.dark-text,
.light-text {
  display: inline-block !important;
  margin-right: 10px !important;
}

.dark-text {
  color: #11304c !important;
  font-weight: bold !important;
}

.light-text {
  color: #333 !important;
  font-weight: 400 !important;
}
.dark-text-info {
  color: #11304c !important;
  font-size: 22px !important;
}
.page-title {
  font-size: 24px !important;
  font-weight: 600 !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  color: #11304c !important;
}
.page-title-about {
  font-size: 32px !important;
  font-weight: bold !important;
  /* margin-bottom: 20px !important;
  margin-top: 20px !important; */
  margin: 0 0 20px;
  color: #11304c !important;
  font-family: "YoungSerif" !important;
}
.page-content {
  font-size: 16px !important;
  line-height: 1.5 !important;
  margin: 0 0 20px;
  color: #8a95a7 !important;
  font-weight: 400 !important;
  justify-content: start !important;
  align-items: start !important;
  text-align: start !important;
  padding: 3px !important;
}
.page-content-about {
  font-size: 20px !important;
  line-height: 1.5 !important;
  /* color: #1a212c !important; */
  color: #11304c !important;
  /* font-weight: 500 !important; */
  padding: 3px !important;

  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.productTitle {
  padding: 2px !important;
  font-size: 35px !important;
  color: #18a1a4 !important;
  font-weight: 550 !important;
  font-family: "YoungSerif" !important;
}
.productSubtitle {
  color: "white" !important;
  opacity: 0.8 !important;
  margin-top: "1rem" !important;
  margin-bottom: "3rem" !important;
}
